/* estilos de footer */
.footer {
  background-color: transparent;
  color: white;
  padding: 20px 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: transform 0.3s ease;
  margin-bottom: -290px; /* Asegúrate de especificar las unidades */
}

.footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Nuevo estilo para el contenedor cuadrado */
.square-container {
  position: relative;
  width: 400px;
  height: 400px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 15px; /* Bordes redondeados del cuadrado */
  margin-top: 20px; /* Ajuste del margen superior */
  background: linear-gradient(to bottom, transparent, #0b052b 25%); /* Degradado de morado claro a oscuro */
}

/* Estilos por defecto (pantallas grandes) */
.circular-menu {
  display: none;
}

@media (max-width: 1024px) {
  .square-container {
    display: flex;
    width: 120%;
  }
  .circular-menu {
    position: absolute; /* Cambiar a absolute para posicionarlo dentro del cuadrado */
    width: 350px;
    height: 350px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease; /* Añadir una transición para suavizar la rotación */
    touch-action: none; /* Deshabilitar la acción táctil por defecto para permitir el arrastre */
  }
}

@media (max-width: 768px) {
  .circular-menu {
    position: absolute; /* Cambiar a absolute para posicionarlo dentro del cuadrado */
    width: 350px;
    height: 350px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease; /* Añadir una transición para suavizar la rotación */
    touch-action: none; /* Deshabilitar la acción táctil por defecto para permitir el arrastre */
  }
}

.nav-items {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.footer-link {
  position: absolute;
  color: white;
  font-size: 16px;
  margin: 10px 0;
  padding: 5px 0; /* Ajustar el padding vertical */
  line-height: 1; /* Ajustar la altura de línea */
  text-align: center; /* Asegurar que el texto esté centrado */
  width: 150px; /* Establecer un ancho fijo */
  border-radius: 15px;
  border: 1px solid white;
  text-decoration: none; /* Quitar el subrayado del enlace */
  transition: color 0.2s, transform 0.2s, background-color 0.2s, border-color 0.2s;
  transform-origin: center center; /* Añadir origen de transformación */
}

.footer-link:hover {
  color: white; /* Mantener el color del texto blanco */
  transform: scale(1.1);
  background-color: transparent; /* Asegurarse de que el fondo no cambie */
  border: 1px solid #fff; /* Cambiar el borde a blanco */
}

.footer-link.active {
  border: 1px solid #fff;
  background-color: transparent; /* Asegurarse de que el fondo no cambie */
  color: #fff; /* Mantener el color del texto blanco */
}

.footer-link.inactive {
  color: gray; /* Hacer que las etiquetas inactivas sean grises */
  border: 1px solid gray; /* Borde gris para etiquetas inactivas */
}

/* Transformaciones para mantener la orientación correcta */
.footer-link:nth-child(1) {
  transform: rotate(0deg) translate(120px) rotate(0deg);
}

.footer-link:nth-child(2) {
  transform: rotate(60deg) translate(120px) rotate(-60deg);
}

.footer-link:nth-child(3) {
  transform: rotate(120deg) translate(120px) rotate(-120deg);
}

.footer-link:nth-child(4) {
  transform: rotate(180deg) translate(120px) rotate(-180deg);
}

.footer-link:nth-child(5) {
  transform: rotate(240deg) translate(120px) rotate(-240deg);
}

.footer-link:nth-child(6) {
  transform: rotate(300deg) translate(120px) rotate(-300deg);
}

/* Asegurar que las etiquetas de los enlaces estén orientadas correctamente */
.circular-menu .footer-link:nth-child(1) {
  transform: rotate(270deg) translate(140px) rotate(90deg);
}

.circular-menu .footer-link:nth-child(2) {
  transform: rotate(210deg) translate(145px) rotate(90deg);
}

.circular-menu .footer-link:nth-child(3) {
  transform: rotate(150deg) translate(140px) rotate(90deg);
}

.circular-menu .footer-link:nth-child(4) {
  transform: rotate(90deg) translate(140px) rotate(90deg);
}

.circular-menu .footer-link:nth-child(5) {
  transform: rotate(30deg) translate(140px) rotate(90deg);
}

.circular-menu .footer-link:nth-child(6) {
  transform: rotate(-30deg) translate(140px) rotate(90deg);
}

@media (min-width: 768px) {
  .footer {
    padding: 40px 0;
  }

  .footer-link {
    font-size: 18px;
  }
}
