/* banner */
.banner {
    width: 100%;
    height: 1000px;
    padding-top: 10%;
    position: relative;
    background: url(../../../public/img/fondos/banner.jpg) center center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #27204d, #191530);
    opacity: 0.5;
}

.bottomFade {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background-image: linear-gradient(to top,
            rgba(25, 21, 48, 0.95),
            rgba(25, 21, 48, 0.5) 70%,
            transparent 90%
        );
}

.contenidoBanner {
    width: 100%;
    height: 100%;
    position: absolute;
    color: white;
    margin-top: 35%;
}

.textoCentral {
    color: white;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-size: 70px;
    font-weight: 900;
    line-height: 87.89px;
    text-align: center;
}

.contenidoBanner p {
    color: white;
    position: relative;
    font-family: 'Roboto', sans-serif;
    line-height: 87.89px;
    text-align: center;
}

/* Media queries para ajustar el diseño en diferentes tamaños de pantalla */
@media (max-width: 820px) {
    .banner {
        height: 700px;  
    }

    .textoCentral {
        font-size: 45px;
        line-height: 37.5px;
    }

    .contenidoBanner p {
        margin-top: 50px;
        font-size: 23px;
        line-height: normal;
    }

    .contenidoBanner {
        margin-top: 10%;
        height: 40%;
        padding: 20px 50px 20px 50px;
    }
}
@media (max-width: 768px) {
    .banner {
        height: 800px;
        margin-bottom: 0%;
    }

    .textoCentral {
        font-size: 45px;
        line-height: 37.5px;
    }

    .contenidoBanner p {
        margin-top: 50px;
        font-size: 23px;
        line-height: normal;
    }

    .contenidoBanner {
        width: 100%;
        height: 50%;
        position: absolute;
        margin-top: 0%;
        padding: 70px 70px 0 70px;
    }
}

@media (max-width: 550px) {
    .banner {
        height: 800px;
        padding-top: 0%;
        margin-bottom: 0%;
    }

    .contenidoBanner{
        height: 100%;
        padding: 200px 30px 0 30px;
    }

    .textoCentral {
        font-size: 35px;
        line-height: normal;
    }

    .textoCentral p {
        font-size: 15px;
        line-height: 16.41px;
    }

}

@media (max-width: 370px){
    .banner {
        height: 600px;
    }

    .contenidoBanner{
        height: 100%;
        padding: 250px 10px 0 10px;
    }

    .textoCentral {
        font-size: 20px;
        line-height: normal;
    }

    .contenidoBanner p {
        font-size: 17px;
        line-height: normal;
    }
}