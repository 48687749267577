/* Estilos generales para la sección de nosotros */
.nosotros {
  width: 100%;
  height: 1100px;
  padding-top: 1%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(25, 21, 48, 0.5), rgba(25, 21, 48, 0.5)), url(../../../public/img/fondos/FondoTesca.png) center center no-repeat;
  background-size: cover;
}

.xd {
  background-position: center;
  background-size: 100%;
  width: 100%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 200px;
  height: 39%;
  margin-top: -10px;
}

.xd h1, .xd p {
  font-family: 'Roboto', sans-serif;
  line-height: 23.44px;
  text-align: center;
  padding-top: 2%;
}

.xd h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: 900;
  line-height: 56.25px;
  text-align: center;
}

.xd p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 23.44px;
  text-align: center;
}

.rectangles {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rectangle, .rectar {
  background: linear-gradient(90deg, #312960 0%, #16122A 100%);
  color: white;
  padding: 15px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 150%;
  margin-left: -100px;
}

.rectar {
  background: linear-gradient(90deg, #16122A 0%, #312960 100%);
}

.mission_vision {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 60px;
}

.card {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 35%;
  height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #241E48;
}

.card h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 37.5px;
  color: white;
}

.card p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 23.44px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .xd {
      padding: 0 100px;
  }

  .rectangle, .rectar {
      width: 120%;
      margin-left: -60px;
  }
}

@media (max-width: 992px) {
  .xd {
      padding: 0 50px;
  }

  .rectangle, .rectar {
      width: 100%;
      margin-left: 0;
  }

  .mission_vision {
      flex-direction: column;
      align-items: center;
  }

  .card {
      width: 80%;
      height: 220px;
      margin-bottom: 20px;
  }
}

@media (max-width: 820px) {
  .xd {
      padding: 0 20px;

  }

  .xd h1 {
      font-size: 36px;
      margin-top: 30px;
  }

  .xd p {
      font-size: 18px;
  }

  .rectangle, .rectar {
      width: 100%;
      margin-left: 0;
  }

  .card {
      width: 90%;
      height: 200px;
      padding: 50 50px;
      
  }
}
@media (max-width: 768px) {
  .xd {
      padding: 0 20px;

  }

  .xd h1 {
      font-size: 36px;
      margin-top: 30px;
  }

  .xd p {
      font-size: 18px;
  }

  .rectangle, .rectar {
      width: 100%;
      margin-left: 0;
  }

  .card {
      width: 90%;
      height: 210px;
      padding: 50 50px;
      
  }
}

@media (max-width: 576px) {
  .nosotros{
    height: 1160px;
  }
  .xd {
      padding: 0 45px 0 45px;
  }

  .xd h1 {
      font-size: 28px;
  }

  .xd p {
      font-size: 16px;
  }

  .rectangle, .rectar {
      width: 100%;
      margin-left: 0;
  }

  .card {
      width: 100%;
      height: 220px;
      padding: 20px;
  }
}

@media (max-width: 370px){
  .card{
    margin-bottom: 50px;
  }
  .nosotros{
    height: 1200px;
  }
  .xd {
    padding: 0 25px 0 25px;
}
}