/* Otros estilos del navbar */
.navbar {
    margin-top: -150px;
    z-index: 1;
    height: 90px;
    width: 100%;
}

.container {
    width: 100%;
    padding-left: 0;
}

.navbar-brand {
    width: auto;
    margin-left: -100px;
}

.navbar-brand img.page-scroller {
    position: absolute;
    left: 80px;
    top: 30px;
    max-width: 100px;
}

.logo-responsive {
    display: none;
    max-width: 55px;
}

.nav-link {
    color: white !important;
    font-size: 20px !important;
    margin-right: 30px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.2s;
}

/* Estilo para el enlace activo */
.nav-link.active {
    /* color: black !important; */
    font-weight: bold;
    background-color: transparent; /* O cualquier otro color que prefieras */
    border: 2px solid #fff; /* Cambiar el borde a blanco */
}


.nav-link:hover,
.nav-link:focus {
    background: transparent;
    border: 1px solid white;
    cursor: pointer;
}

@media (max-width: 768px) {
    .logo-responsive {
        display: block;
        left: 80px;
    }

    .navbar-brand {
        margin-left: 0;
    }

    .nav-link {
        font-size: 16px;

    }

    .navbar-collapse.show {
        background-color: rgba(39, 32, 77, 0.9);
    }

    .nav-link.active,
    .nav-link:hover,
    .nav-link:focus {
        background-color: #27204d;
        color: white;
                display: none;
    }
}