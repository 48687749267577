/* estilos de social */
.social {
  background-color: #af4b12;
  color: white;
  padding: 2rem 0;
  position: fixed; /* Fija el footer en la parte inferior */
  bottom: -100px; /* Comienza fuera de la vista */
  left: 0;
  width: 100%;
  height: 10%;
  transition: bottom 0.5s ease-in-out; /* Añade una transición suave */
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
}

.social.visible {
  bottom: 0; /* Mueve el footer a la vista */
}

.social .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-left {
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin-bottom: 100px; /* Separación entre los íconos */
}

.social-logos {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10%;
  margin-bottom: 225px; /* Separación entre los íconos */
}

.social-logos img {
  width: 30px;
  height: 30px;
  margin-bottom: 50px; /* Separación entre los íconos */
}

@media (min-width: 768px) {
  .social .container {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .social {
    display: none; /* Ocultar el footer en pantallas de 1024px o menos */
  }
}