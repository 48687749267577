/* Estilos generales para la sección de nosotros */
.clientes {
    width: 100%;
    border-style: solid;
    height: auto;
    padding-top: 1%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(25, 21, 48, 0.5), rgba(25, 21, 48, 0.5)), url(../../../public/img/fondos/FondoTesca.png) center center no-repeat;
    background-size: cover;
    overflow: hidden;
}

.text1 {
    background-position: center;
    background-size: 100%;
    width: 100%;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 200px;
}

.text1 h1,
.nuestra_empresas h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 56.25px;
    text-align: center;
}
.slickSlider .slickSlide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.slickSlider .slickSlide img {
    max-width: 200px;
    max-height: 200px;
    display: block;
    filter: grayscale(100%);
}

.slickSlider {
    max-width: 100%;
    margin: 20px auto;
    box-shadow: 0px 0px 6.7px 2px #00000040 inset;
}


.cards_container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.card {
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.card p {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    margin-top: 10%;
}

.card_image {
    width: 70%;
    height: auto;
    margin-bottom: 1px;
    margin-left: -60px;
    margin-top: 5%;
}

.texto_azul {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    line-height: 17.58px;
    text-align: left;
    color: #1C4291;
}

.texto_azul_claro {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    line-height: 17.58px;
    text-align: left;
    color: #2EB8ED;

}

.helvexImage {
    position: relative;
    top: 50%;
    transform: translateY(70%);
}

.accuracity {
    position: relative;
    top: 50%;
    transform: translateY(50%);
}
.Mercado {
    position: relative;
    top: 50%;
    transform: translateY(70%);
}
.Presidencia {
    position: relative;
    top: 50%;
    transform: translateY(50%);
}

/* Estilos para el carrusel de tarjetas */
.cardCarousel {
    width: 80%;
    margin: 40px auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cardCarousel .slick-dots {
    bottom: -30px;
}
.cardCarousel button{
    margin-left: -100px;
    margin-right: -50px;
}
.cardCarousel button::before {
    color:#27204D ;
    font-size: 50px
    
  }
  .slick-next:before {
    color: black;
    font-size: 50px;
  }
@media (max-width: 820px) {
    .social_logos,
    .logo_left {
        display: none;
    }

    .cardCarousel {
        width:150%;
        /* Ajuste del ancho del carrusel para tablets */
    }

    .slickSlider .slickSlide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px; /* Aumenta el padding si es necesario */
    }

    .slickSlider .slickSlide img {
        max-width: 150px; /* Ajusta el tamaño de las imágenes si es necesario */
        max-height: 100px;
        display: block;
        filter: grayscale(100%);
    }

    .card {
        width: 80%; /* Ajuste del ancho de las cartas para tablets */
        height: 400px; /* Deja que la altura se ajuste automáticamente */
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .card_image {
        width: 80%; /* Ajuste del ancho de la imagen dentro de las cartas */
        margin-left: 0;
    }
    .accuracity {
        position: relative;
        top: 50%;
        transform: translateY(50%);
    }
    .Presidencia {
        position: relative;
        top: 50%;
        transform: translateY(50%);
    }
    .Mercado {
        position: relative;
        top: 50%;
        transform: translateY(70%);
    }
}

@media (max-width: 768px) {
    .cardCarousel {
        width:150%;
        /* Ajuste del ancho del carrusel para tablets */
    }

    .slickSlider .slickSlide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px; /* Aumenta el padding si es necesario */
    }

    .slickSlider .slickSlide img {
        max-width: 150px; /* Ajusta el tamaño de las imágenes si es necesario */
        max-height: 100px;
        display: block;
        filter: grayscale(100%);
    }

    .card {
        width: 80%; /* Ajuste del ancho de las cartas para tablets */
        height: 400px; /* Deja que la altura se ajuste automáticamente */
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .card_image {
        width: 80%; /* Ajuste del ancho de la imagen dentro de las cartas */
        margin-left: 0;
    }
    .Presidencia {
        position: relative;
        top: 50%;
        transform: translateY(50%);
    }
    .Mercado {
        position: relative;
        top: 50%;
        transform: translateY(70%);
    }
}

@media (max-width: 480px) {
    .cardCarousel {
        display: block;
        width:82%;
        /* Mostrar el carrusel */
    }
    .cards_container {
        display: none;
        /* Ocultar las cartas individuales */
    }
    .slickSlider .slick-slide {
        padding: 0 10px; /* Aumenta el padding para mayor espacio entre slides */
    }

    .slickSlider .slick-list {
        margin: 0 -10px; /* Ajuste negativo si el padding hace que el contenedor se desborde */
    }

    .slickSlider .slickSlide img {
        max-width: 100px;
        max-height: 60px;
        display: block;
        filter: grayscale(100%);
    }

    .card {
        width: auto;
        /* Ajuste del ancho de las cartas para pantallas pequeñas */
        height: 500px;
        margin: 0 auto;
        margin-bottom: 20px;
        /* Espacio entre las cartas */
    }
    .card_image {
        width: 80%;
        /* Ajuste del ancho de la imagen dentro de las cartas */
        margin-left: 0;
    }
    .accuracity {
        position: relative;
        top: 50%;
        transform: translateY(50%);
    }
    .Presidencia {
        position: relative;
        top: 50%;
        transform: translateY(50%);
    }
    .Mercado {
        position: relative;
        top: 50%;
        transform: translateY(70%);
    }

    .text1,
    .nuestra_empresas {
        padding: 10px;
    }

    .text1 h1,
    .nuestra_empresas h1  {
        font-size: 28px;
    }
    @media (max-width: 320px) {
        .card {
            width: 250px;
            /* Ajuste del ancho de las cartas para pantallas pequeñas */
            height: 500px;
            margin: 0 auto;
            margin-bottom: 20px;
            /* Espacio entre las cartas */
        }       
    }
}