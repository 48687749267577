/* Estilos generales para la sección de servicios */
.servicio {
  width: 100%;
  height: auto;
  padding-top: 1%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(25, 21, 48, 0.5), rgba(25, 21, 48, 0.5)), url(../../../public/img/fondos/FondoTesca.png) center center no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  overflow: hidden;
}

.textoprincipal, .textosecundario {
  background-position: center;
  background-size: 100%;
  width: 100%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.textoprincipal {
  height: 39%;
  margin-top: -10px;
}

.textosecundario {
  height: 33%;
  padding-top: 4%;
}

.textoprincipal h1, .textoprincipal p, .textosecundario p {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  text-align: center;
  padding-top: 2%;
}

.textoprincipal h1 {
  margin: 30px;
  font-size: 2.5rem;
  font-weight: 900;
}

.textoprincipal p, .textosecundario p {
  font-size: 1.25rem;
  font-weight: 300;
}

/* Estilos para el contenedor y filas de vídeo */
.video-container {
  display: flex;
  flex-direction: column; /* Cambia la dirección del flex a columna */
  align-items: center;
  width: 100%;
}

.video-row {
  width: 100%;
  margin: 5px 0; /* Espacio vertical entre los vídeos */
  box-sizing: border-box;
}

.video-row video {
  width: 100%;
  height: auto;
}


/* Estilos responsive */
@media (max-width: 1024px) {
  .textoprincipal {
    padding: 0 20px;
  }
  
  .textoprincipal h1 {
    font-size: 2rem;
  }

  .textoprincipal p, .textosecundario p {
    font-size: 1rem;
  }
}

@media (max-width: 820px) {
  .servicio {
    align-items: center;
  }

  .textoprincipal, .textosecundario {
    padding: 0 15px;
  }

  .textoprincipal h1 {
    font-size: 34px;
  }

  .textoprincipal p, .textosecundario p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .textoprincipal, .textosecundario {
    padding: 10px 50px 30px 50px;
  }

  .textoprincipal h1 {
    font-size: 35px;
  }

  .textoprincipal p, .textosecundario p {
    font-size: 20px;
    line-height: 1.4;
    text-align: justify;
  }

  .bottomFade {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-image: linear-gradient(to top,
        rgba(25, 21, 48, 0.95),
        rgba(25, 21, 48, 0.5) 70%,
        transparent 90%);
  }
}

@media (max-width: 550px) {
  .textoprincipal h1 {
    font-size: 32px;
  }

  .textoprincipal, 
  .textosecundario{
    padding: 20px 30px 30px 30px;
  }

  .textoprincipal p, .textosecundario p {
    font-size: 15px;
    text-align: justify;
    line-height: 1.4;
  }

  .video-row {
    width: 100%;
    margin: 1px 0 1px 0; /* Espacio vertical entre los vídeos */
    box-sizing: border-box;
  }

}
