/* Estilos originales */
.Metodologia {
  height: 1500px;
  margin-top: -8%;
  font-family: 'Roboto', sans-serif;
}

.info1 {
  background: white url(../../../public/img/fondos/Subtract.png) no-repeat center/100%;
  width: 100%;
  height: 39%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 200px;
  margin-top: -10px;
}

.info1 h1 {
  margin: 30px;
}

.info1 p,
.info2 p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  /* line-height: 23.44px; */
  text-align: center;
}

.cards {
  width: 100%;
  height: 38%;
  padding: 30px;
  background: url(../../../public/img/fondos/Rectangle.png) no-repeat center/cover;
}

.info2 {
  background: transparent url(../../../public/img/fondos/Subtract-1.png) no-repeat center/100%;
  width: 100%;
  height: 400px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 200px 50px 200px; 
}

.cardArea {
  width: 370px;
  height: 420px;
  background: #191530;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  color: white;
  padding: 50px 20px 0 30px;
  overflow-wrap: break-word; /* Asegura que el texto no sobrepase el ancho de la tarjeta */
}

.line {
  background: white;
  width: 100px;
  height: 5px;
  margin: 20px 0 20px 0;
}

.imgCard {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 50px;
  margin-top: -110px;
}

.img-tierra {
  margin-top: -50px;
}

/* Añadiendo responsividad */
@media (max-width: 1024px) {
  .Metodologia {
    height: auto;
    margin-bottom: -1px;
  }

  .info1 {
    background: white url(../../../public/img/fondos/Subtract.png) no-repeat center/cover;
    padding: 0 100px;
    height: auto;
    margin-top: -320px;
    z-index: 1;
    /* Asegurando que la barra de navegación esté sobre otros elementos */
  }

  .cards {
    background: url(../../../public/img/fondos/Rectangle.png) no-repeat center/cover;
    padding: 20px 50px;
    transform: translateX(20px);
    height: 50%;
    /* Mueve la carta hacia la derecha */
  }

  .info2 {
    background: white url(../../../public/img/fondos/Subtract-1.png) no-repeat center/cover;
    padding: 50px 20px;
    /* Asegurando suficiente espacio alrededor del texto */
  }

  .info1 p,
  .info2 p {
    font-size: 12px;
    /* Reducir tamaño de fuente para pantallas medianas */
  }

  .cardArea {
    width: 260px; /* Reducido para dispositivos medianos */
    height: auto; /* Ajusta la altura automáticamente según el contenido */
    padding: 30px 20px; /* Ajusta el padding para un mejor manejo del texto */
  }

  .img-tierra,
  .img-fuego {
    transform: translateY(-50px);
    /* Ajustar la distancia como sea necesario */
  }

  .imgCard img {
    width: 70%;
    /* Reducción adicional para ajustar las imágenes en pantallas pequeñas */
    transform: translateY(65px);
    margin-bottom: 20%;
  }
}


@media (max-width: 820px) {
  .info1 {
    background: white url(../../../public/img/fondos/Subtract.png) no-repeat center/cover;
    padding: 80px; /* Más espacio interno para facilitar la lectura */
    height: auto; /* Adaptabilidad en la altura */
    margin-top: 0; /* Ajustar el margen superior si es necesario */
  }

  .info1 h1 {
    font-size: 30px; /* Ligeramente más pequeño que en dispositivos más grandes */
    margin-bottom: 40px;
  }

  .info1 p {
    font-size: 20px; /* Ajuste del tamaño de fuente para parrafos */
    line-height: normal;
    text-align: justify;
  }
  .info2 p {
    font-size: 20px; /* Ajuste del tamaño de fuente para parrafos */
    line-height: normal;
    text-align: justify;
  }

  .cards {
    background: url(../../../public/img/fondos/Rectangle.png) no-repeat center/cover;
    padding: 20px 50px;
    transform: translateX(20px);
    height: 40%;

  }
  .cardArea {
    width: 90%; /* Hacer que las tarjetas ocupen todo el ancho disponible */
    padding: 20px; /* Espacio interno adecuado */
    margin-bottom: 20px; /* Añadir espacio debajo de cada tarjeta para evitar que se superpongan visualmente */
  }

  .imgCard img {
    width: 70%;
    /* Reducción adicional para ajustar las imágenes en pantallas pequeñas */
    transform: translateY(65px);
    margin-bottom: 20%;
  }

  .info2 {
    background: white url(../../../public/img/fondos/Subtract-1.png) no-repeat center/cover;
    padding: 170px 80px 100px 80px;
    height: 35%;
  }
}


@media (max-width: 768px) {

  .Metodologia {
    margin-top: 0%;
  }

  .info1{
    padding: 0 60px 60px 60px;
    height: 40%;
  }

  .info2 {
    padding: 0 20px;
    height: 40%;
  }

  .info1 h1 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .info1 p,
  .info2 p {
    font-size: 20px;
    /* Reducir tamaño de fuente para dispositivos pequeños */
  }

  .info2 {
    padding: 80px 50px 0 50px;
  }

  .cards {
    padding: 10px 20px;
    transform: translateX(14px);
    /* Mueve la carta hacia la derecha */
  }

  .cardArea {
    width: 320px; /* Mantener ancho reducido */
    padding: 20px; /* Reducir el padding para más espacio de texto */
  }

  .imgCard img {
    width: 70%;
    /* Reducción adicional para ajustar las imágenes en pantallas pequeñas */
    transform: translateY(65px);
    margin-bottom: 20%;
  }

  .img-tierra,
  .img-fuego {
    transform: translateY(19px);
    /* Ajustar la distancia como sea necesario en pantallas pequeñas */
  }

  .responsiveText {
    font-size: 12px;
    margin-bottom: -15px;
  }

  .textResponsive {
    display: none;
  }
}

@media (max-width: 550px) {
  .Metodologia {
    margin-top: 0%;
    width: 100%;
  }

  .info1,
  .info2 {
    padding: 0 20px;
    height: 40%;
  }
  
 .info1 {
    background: white url(../../../public/img/fondos/Subtract.png) no-repeat center/cover;
    padding: 10px 25px 50px 25px; /* Más espacio interno para facilitar la lectura */
    height: auto; /* Adaptabilidad en la altura */
    margin-top: 0px;
    z-index: 1;
  }
  .info1 h1 {
    font-size: 32px; /* Ligeramente más pequeño que en dispositivos más grandes */
    margin-bottom: 25px;
  }
  .info1 p,
  .info2 p {
    font-size: 15px;
    text-align: justify;
    /* Reducir tamaño de fuente para dispositivos pequeños */
  }
  .cards {
    background: url(../../../public/img/fondos/Rectangle.png) no-repeat center/cover;
    transform: translateX(20px);
    height: 450px;
    padding: 30px;
    margin-left: -20px;
    width: 100%;
    /* Mueve la carta hacia la derecha */
  }
  .cardArea {
    width: 95%;
    height: 370px;
    padding: 30px 20px;
  }

  .cardArea .content{
    width: 145px;
  }

    .info2 {
    background: white url(../../../public/img/fondos/Subtract-1.png) no-repeat center/cover;
    padding: 140px 25px 50px 25px; /* Más espacio interno para facilitar la lectura */
    height: 47%;
  }
}

@media (max-width: 370px){
  .Metodologia {
    height: 1300px;
  }
  .cards {
    height: 40%;
    padding: 50px 10px 10px 10px;
    margin-left: -20px;
    width: 100%;
    
    /* Mueve la carta hacia la derecha */
  }
  .cardArea {
    width: 100%;
    height: 400px;
    padding: 30px 20px;
  }
  .info2 {
    margin-top: -90px;
    padding: 60px 25px 10px 25px; /* Más espacio interno para facilitar la lectura */
    height: 560px;
  }
}

@media (min-width: 1500px){
  .cardArea {
    width: 420px;
    height: 420px;
  }
}