/* Estilos generales para la sección de contacto */
.contacto {
  width: 100%;
  height: 1000px;
  padding-top: 1%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(25, 21, 48, 0.5), rgba(25, 21, 48, 0.5)), url(../../../public/img/fondos/fondoazul.png) center center no-repeat;
  background-size: cover;
}

.titulo {
  background-position: center;
  background-size: 100%;
  width: 100%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 39%;
  margin-top: -80px;
}

.titulo h1 {
  font-family: 'Roboto', sans-serif;
  line-height: 23.44px;
  text-align: center;
  padding-top: 2%;
  margin: 50px 0;
  font-size: 40px;
  font-weight: 900;
}

.titulo p {
  font-size: 18px;
  font-weight: 300;
  margin-top: 1px;
  margin-bottom: 5px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.prueba {
  background: transparent;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  box-shadow: 2px 20px 60px 0px #00000080, -1px -1px 0px 0px rgb(138, 135, 135);
  border-color: rgba(124, 120, 120, 0.856);
  margin-bottom: 50px;
  margin-top: 1px;
}

.formulario {
  background: transparent;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
}

.formulario form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formulario textarea {
  height: 150px;
}

.enviado{
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
}

.formulario input, .formulario textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #23225866;
  color: white;
  font-size: 16px;
  width: 100%;
}

.formulario input::placeholder, .formulario textarea::placeholder {
  color: #DDD;
  opacity: 1;
  font-size: 16px;
}

.formulario input:focus, .formulario textarea:focus {
  outline: none;
  border: 2px solid #4140a1;
}

.formulario button {
  padding: 10px 15px;
  width: 100%;
  max-width: 250px;
  margin-top: 20px;
  border: 2px solid white;
  border-radius: 50px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.formulario button:hover {
  background-color: #4140a1;
}
/* Responsivo para pantallas pequeñas */
@media (max-width: 768px) {
  .contacto {
    height: auto;
    padding: 30% 0;
  }

  .titulo {
    padding: 0 10px;
  }

  .titulo h1 {
    font-size: 32px;
    margin: 20px 0;
    line-height: 1.5; 
  }

  .titulo p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .prueba {
    box-shadow: none;
    margin: 20px 0;
    box-shadow: 2px 20px 60px 0px #00000080, -1px -1px 0px 0px rgb(138, 135, 135);
    width: 90%;
  }

  .formulario {
    padding: 10px;
  }

  .formulario input, .formulario textarea {
    font-size: 14px;
  }

  .formulario button {
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .titulo h1 {
    font-size: 28px;
    line-height: 1.5; 
  }

  .titulo p {
    font-size: 14px;
  }
  .prueba {
    box-shadow: none;
    margin: 20px 0;
    box-shadow: 2px 20px 60px 0px #00000080, -1px -1px 0px 0px rgb(138, 135, 135);
    width: 90%;
  }
  .formulario button {
    width: 100%;
  }
}

@media (max-width: 370px){
  .contacto {
    height: 800px;
  }
}